import React from "react"
import PageTitle from "../../components/PageTitle"
import ContentBox from "../../components/Content-box"
import Layout from "../../components/Layout"

const Tarieven = () => {
  return (
    <Layout title="Tarieven">
      <PageTitle title="Tarieven" />
      <ContentBox>
        <h3>Tarieven</h3>
        <table>
          <tbody>
            <tr>
              <td>Reguliere zitting fysiotherapie</td>
              <td>€35</td>
            </tr>
            <tr>
              <td>Intake en onderzoek na verwijzing</td>
              <td>€47</td>
            </tr>
            <tr>
              <td>Screening, intake en onderzoek; Directe toegankelijkheid</td>
              <td>€47</td>
            </tr>
            <tr>
              <td>Zitting manuele therapie volgens mulligan</td>
              <td>€45</td>
            </tr>
            <tr>
              <td>Zitting psychosomatische Fysiotherapie</td>
              <td>€48</td>
            </tr>
            <tr>
              <td>Zitting geriatrische Fysiotherapie</td>
              <td>€45</td>
            </tr>
            <tr>
              <td>Aan huis toeslag</td>
              <td>€15</td>
            </tr>
            <tr>
              <td>Taping inclusief materiaal</td>
              <td>€20</td>
            </tr>
          </tbody>
        </table>
        <p>
          Afspraken uiterlijk 24 uur vooraf annuleren!!! Indien u de afspraak
          niet 24 uur uur van te voren afzegt, wordt u een no-show tarief van
          €25,- particulier in rekening gebracht. Wij kunnen de verzuimde
          behandeling NIET in rekening brengen bij uw verzekeraar.
        </p>
      </ContentBox>
    </Layout>
  )
}

export default Tarieven
